<template>
  <div class="container">
    <div class="main">
      <div v-if="albums.length === 1" class="box box1">
        <img v-for="album of albums" :key="album" :src="album.thumbnail_url" />
      </div>
      <div v-else-if="albums.length === 2" class="box box2">
        <img v-for="album of albums" :key="album" :src="album.thumbnail_url" />
      </div>
      <div v-else-if="albums.length === 3" class="box box3">
        <div class="top">
          <img
            v-for="album of albums.slice(0, 1)"
            :key="album"
            :src="album.thumbnail_url"
          />
        </div>
        <div class="bottom">
          <img
            v-for="album of albums.slice(1, 3)"
            :key="album"
            :src="album.thumbnail_url"
          />
        </div>
      </div>
      <div v-else-if="albums.length === 4" class="box box4">
        <img v-for="album of albums" :key="album" :src="album.thumbnail_url" />
      </div>
      <div v-else-if="albums.length === 5" class="box box5">
        <div class="left">
          <img
            v-for="album of albums.slice(0, 3)"
            :key="album"
            :src="album.thumbnail_url"
          />
        </div>
        <div class="right">
          <img
            v-for="album of albums.slice(3, 5)"
            :key="album"
            :src="album.thumbnail_url"
          />
        </div>
      </div>
      <div v-else class="box box6">
        <img v-for="album of albums" :key="album" :src="album.thumbnail_url" />
      </div>
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
export default {
  name: "album",
  data() {
    return {
      campaignId: "",
      albums: [],
    };
  },
  created() {
    let { campaignId } = this.$route.query;
    this.getCampaignAlbum((this.campaignId = campaignId));
  },
  methods: {
    /** 获取活动相册 */
    getCampaignAlbum(campaignId) {
      const _ = this;
      $fetch
        .post("CampaignAlbum", {
          campaignId,
        })
        .then((album) => {
          _.albums = album.pics.slice(0, 6);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  position: relative;
  width: 100%;
  height: 80.47vw;
  background: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183d48e-e9cb-4692-df21-674e664d3621?x-oss-process=style/origin)
    no-repeat center center / cover;

  > .main {
    position: absolute;
    left: px2rem(94.2 * $multipleCount);
    top: px2rem(40 * $multipleCount);
    width: px2rem(148.2 * $multipleCount);
    height: px2rem(214.8 * $multipleCount);
    transform: rotate(5.8deg);

    > .box {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        display: block;
        object-fit: cover;
      }
    }

    > .box1 > img {
      width: 100%;
      height: 100%;
    }

    > .box2 {
      @include column;

      > img {
        width: 100%;
        height: calc((100% - px2rem(3 * $multipleCount)) / 2);

        &:last-child {
          margin-top: px2rem(3 * $multipleCount);
        }
      }
    }

    > .box3 {
      @include column;

      > .top {
        width: 100%;
        height: px2rem(148.2 * $multipleCount);

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > .bottom {
        @include row;
        width: 100%;
        height: calc(
          100% - px2rem(148.2 * $multipleCount) - px2rem(3 * $multipleCount)
        );
        margin-top: px2rem(3 * $multipleCount);

        > img {
          width: calc((100% - px2rem(3 * $multipleCount) / 2));
          height: 100%;

          &:last-child {
            margin-left: px2rem(3 * $multipleCount);
          }
        }
      }
    }

    > .box4 {
      @include row(space-between, flex-start);
      flex-wrap: wrap;
      align-content: space-between;

      > img {
        width: calc((100% - px2rem(3 * $multipleCount)) / 2);
        height: calc((100% - px2rem(3 * $multipleCount)) / 2);
      }
    }

    > .box5 {
      @include row;

      > .left {
        @include column;
        justify-content: space-between;
        width: calc((100% - px2rem(3 * $multipleCount)) / 2);
        height: 100%;

        > img {
          width: 100%;
          height: calc((100% - px2rem(3 * $multipleCount) * 2) / 3);
        }
      }

      > .right {
        @include column(space-between, flex-start);
        width: calc((100% - px2rem(3 * $multipleCount)) / 2);
        height: 100%;
        margin-left: px2rem(3 * $multipleCount);

        > img {
          width: 100%;
          height: calc((100% - px2rem(3 * $multipleCount) / 2));

          &:last-child {
            margin-top: px2rem(3 * $multipleCount);
          }
        }
      }
    }

    > .box6 {
      @include row(space-between, flex-start);
      flex-wrap: wrap;
      align-content: space-between;

      > img {
        width: calc((100% - px2rem(3 * $multipleCount)) / 2);
        height: calc((100% - px2rem(3 * $multipleCount) * 2) / 3);
      }
    }
  }
}
</style>
